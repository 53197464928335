import { useState } from "react"
import { ApiResult, SearchConfig } from "../lib/types"
import axios from "axios"
const apiUrl = "https://pelias.waveout.app"
const defaultRequestParams = { "boundary.country": "AUT" }

type PlaceSearchReturn = {
  loading: boolean
  results: ApiResult | null
  search: (config: SearchConfig) => void
  error: any
}

const usePlaceSearch = (): PlaceSearchReturn => {
  const [results, setResults] = useState<ApiResult | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const search = async (config: SearchConfig) => {
    const { text, center, radius, nodeTypes } = config

    if (text.trim() === "") {
      console.log("empty")
      setResults(null)
      return
    }

    setLoading(true)
    try {
      const [lng, lat] = center

      const boundingParams = {
        "focus.point.lat": lat,
        "focus.point.lon": lng,
        "boundary.circle.lat": lat,
        "boundary.circle.lon": lng,
        "boundary.circle.radius": radius,
        layers: nodeTypes.join(","),
        size: 50,
      }

      const url = `${apiUrl}/v1/search`
      const res = await axios.get(url, {
        params: { ...boundingParams, text },
      })
      setResults(res.data)
    } catch (e) {
      setResults(null)
      setError(e)
    }

    setLoading(false)
  }

  return { loading, results, search, error }
}

export default usePlaceSearch
