import { useState } from "react"
import { Feature, Leg, RouteResponse } from "../lib/types"
import polyline from "@mapbox/polyline"
import axios from "axios"
const apiUrl = "https://valhalla.waveout.app"

type Point = {
  latitude: number
  longitude: number
}

const requestRoute = async (
  origin: Point,
  destination: Point
): Promise<RouteResponse> => {
  const url = `${apiUrl}/route`

  const params = {
    locations: [
      { lat: origin.latitude, lon: origin.longitude },
      { lat: destination.latitude, lon: destination.longitude },
    ],
    costing: "pedestrian",
    directions_options: {
      units: "kms",
    },
  }

  const { data } = await axios.post(url, params)
  return data
}

type Coords = [number, number][]

const pointFromFeature = (feature: Feature): Point => {
  const coords = feature.geometry.coordinates
  return { latitude: coords[1], longitude: coords[0] }
}

const resToRoutes = (res: RouteResponse): Coords[] => {
  const legs = res.trip.legs.reduce((legRoutes: Coords, leg: Leg) => {
    const coords = polyline.decode(leg.shape, 6)
    const coordTemp = []
    coords.forEach(coord => coordTemp.push(coord.reverse()))
    return legRoutes.concat(coordTemp)
  }, [])
  return [legs]
}

export default () => {
  const [routes, setRoutes] = useState<Coords[]>([])
  const clear = () => setRoutes([])
  const findRoute = async (origin: Feature, destination: Feature) => {
    const o = pointFromFeature(origin)
    const d = pointFromFeature(destination)
    const res = await requestRoute(o, d)
    const routes = resToRoutes(res)
    setRoutes(routes)
  }

  return { clear, routes, findRoute }
}
