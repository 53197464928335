import { StaticImage } from "gatsby-plugin-image"
import Switch from "react-switch"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import ReactMapboxGl, { Feature, Layer, Popup } from "react-mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import usePlaceSearch from "../hooks/usePlaceSearch"
import { NodeTypes, SearchConfig } from "../lib/types"
import useRoute from "../hooks/useRoute"

const DEFAULT_CENTER: [number, number] = [16.363449, 48.210033]
const DEFAULT_RADIUS_METERS = 5000

type TripState = {
  origin: any
  destination: any
}

type TripContextParams = {
  state: TripState
  dispatch: (action: { type: string; marker: any }) => void
}

const TripContext = createContext<TripContextParams | null>(null)

const IndexPage = () => {
  const { search, results } = usePlaceSearch()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [shouldShowMap, setShouldShowMap] = useState<boolean>(true)
  const [center, setCenter] = useState<[number, number]>(DEFAULT_CENTER)
  const [marker, setMarker] = useState(null)
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS_METERS)
  const [nodeTypes, setNodeTypes] = useState<NodeTypes>([])
  const { clear, routes, findRoute } = useRoute()

  const [state, dispatch] = useReducer(
    (localState, action) => {
      switch (action.type) {
        case "SET_ORIGIN":
          return { ...localState, origin: action.marker }
        case "SET_DESTINATION":
          return { ...localState, destination: action.marker }
      }
    },
    { origin: null, destination: null }
  )

  useEffect(() => {
    if (state.origin && state.destination) {
      findRoute(state.origin, state.destination)
    }
  }, [state.origin, state.destination])

  useEffect(() => {
    setMarker(null)
    const config: SearchConfig = {
      text: searchTerm,
      center,
      radius,
      nodeTypes,
    }

    search(config)
  }, [searchTerm, radius, center, nodeTypes])

  const features = results?.features || []

  const updateCenter = map => {
    const { lat, lng } = map.getCenter()
    setCenter([lng, lat])
  }

  const isRuntime = typeof window !== "undefined"
  if (!isRuntime) {
    return <></>
  }

  return (
    <Layout>
      <Seo title="Home" />
      <div className="flex" style={{ width: "100vw" }}>
        <TripContext.Provider value={{ state, dispatch }}>
          <div className="w-2/5 overflow-y-scroll" style={{ height: "100vh" }}>
            <header className="p-6 mb-6 bg-gray-800">
              <div className="flex items-center justify-between text-white">
                <StaticImage
                  src="../images/waveout-logo-horizontal.svg"
                  alt="logo"
                />
                <div className="flex items-center">
                  <span className="mr-2">Map</span>

                  <Switch
                    onChange={() => setShouldShowMap(!shouldShowMap)}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={!shouldShowMap}
                  />

                  <span className="ml-2">Data</span>
                </div>
              </div>
            </header>
            <div>
              <SearchForm
                center={center}
                setRadius={setRadius}
                setCenter={setCenter}
                onSearch={term => setSearchTerm(term)}
                setNodeTypes={setNodeTypes}
              />
            </div>
          </div>
          <div className="w-3/5">
            {shouldShowMap ? (
              <MapContainer
                features={features}
                updateCenter={updateCenter}
                center={center}
                marker={marker}
                setMarker={setMarker}
                routes={routes}
              />
            ) : (
              <code>
                <pre
                  style={{ height: "100vh" }}
                  className="p-8 font-mono text-sm bg-gray-100 overflow-y-scroll"
                >
                  {results
                    ? JSON.stringify(results.features, null, 4)
                    : "No results"}
                </pre>
              </code>
            )}
          </div>
        </TripContext.Provider>
      </div>
    </Layout>
  )
}

export default IndexPage

const MapContainer = ({
  features,
  center,
  updateCenter,
  marker,
  routes,
  setMarker,
}) => {
  const mapboxStyle = "mapbox://styles/mapbox/streets-v11"
  const [imageReady, setImageReady] = useState<boolean>(false)
  // const mapboxStyle = "mapbox://styles/aaroncruz/ck3sslwbs0bpr1druqaflcyvk"
  const lineLayout = {
    "line-cap": "round" as "round",
    "line-join": "round" as "round",
  }

  const linePaint = {
    "line-color": "#4790E5",
    "line-width": 3,
  }

  const Map = useMemo(() => {
    return ReactMapboxGl({
      accessToken:
        "pk.eyJ1IjoiYWFyb25jcnV6IiwiYSI6ImNreW83MXp1NjN4ZjQyeHBidjlyNWE4ZzUifQ.l-kiJtIygM_ifHpWY0t0mw",
    })
  }, [])

  const img = useMemo(() => {
    let image = new Image()
    const src = require("../images/Marker.svg")
    image.src = src.default
    image.onload = () => setImageReady(true)
    return image
  }, [])

  const images: any = ["place-marker", img]
  const layerLayout = { "icon-image": "place-marker", "icon-size": 1 }

  const popup = feature => {
    console.log("feature", feature)
    setMarker(feature)
  }

  const colors = [
    "#FFC300",
    "#FF5733",
    "#C70039",
    "#900C3F",
    "#581845",
  ].reverse()

  return (
    <Map
      style={mapboxStyle}
      containerStyle={{
        height: "100vh",
      }}
      onMoveEnd={updateCenter}
      onStyleLoad={updateCenter}
      center={center}
    >
      {routes.map((route, i) => (
        <Layer
          type="line"
          id={`route-${i}`}
          key={i}
          layout={lineLayout}
          paint={{ ...linePaint, "line-color": colors[i] }}
        >
          <Feature coordinates={route} />
        </Layer>
      ))}

      {imageReady && (
        <>
          <Layer type="symbol" id="marker" layout={layerLayout} images={images}>
            {features.map(feature => (
              <Feature
                key={feature.properties.id}
                onClick={() => popup(feature)}
                coordinates={feature.geometry.coordinates}
              />
            ))}
          </Layer>
          {marker && (
            <InfoBox marker={marker} onClose={() => setMarker(null)} />
          )}
        </>
      )}
    </Map>
  )
}

const InfoBox = ({ marker, onClose }) => {
  const title = marker.properties.name
  const coords = marker.geometry.coordinates
  const {
    addendum,
    distance,
    county,
    label,
    localadmin,
    layer,
    region,
    match_type,
    source_id,
  } = marker.properties

  const { dispatch } = useContext(TripContext)

  const defs = [
    { title: "County", value: county },
    { title: "Label", value: label },
    { title: "Local Admin.", value: localadmin },
    { title: "Layer type", value: layer },
    { title: "Region", value: region },
    { title: "Match type", value: match_type },
    { title: "Source ID", value: source_id },
  ]

  return (
    <Popup
      coordinates={marker.geometry.coordinates}
      offset={{
        "bottom-left": [12, -8],
        bottom: [0, -8],
        "bottom-right": [-12, -8],
      }}
    >
      <div style={{ minWidth: "250px" }}>
        <h4 className="flex align-center justify-between mb-1">
          <span className="text-lg font-semibold">{title}</span>
          <a className="cursor-pointer font-semibold" onClick={onClose}>
            X
          </a>
        </h4>
        <p className="mb-2 text-sm text-gray-400 font-semibold">
          Distance from center: {distance} km
        </p>
        <dl>
          {defs.map(({ title, value }) => (
            <>
              {value && (
                <React.Fragment key={title}>
                  <dt className="mb-1 uppercase text-xs text-gray-400 font-semibold">
                    {title}
                  </dt>
                  <dd className="mb-2 text-sm font-medium">{value}</dd>
                </React.Fragment>
              )}
            </>
          ))}
        </dl>
        <div className="px-4 flex justify-between">
          <a
            className="cursor-pointer"
            onClick={() =>
              dispatch({ type: "SET_ORIGIN", marker: { ...marker } })
            }
          >
            Origin
          </a>
          <a
            className="cursor-pointer"
            onClick={() =>
              dispatch({ type: "SET_DESTINATION", marker: { ...marker } })
            }
          >
            Destination
          </a>
        </div>
      </div>
    </Popup>
  )
}

const SearchForm = ({
  setNodeTypes,
  onSearch,
  center,
  setCenter,
  setRadius,
}) => {
  const DEFAULT_RADIUS_KM = 5
  const [term, setTerm] = useState<string>("")
  const [lat, setLat] = useState<number>(center[1])
  const [lng, setLng] = useState<number>(center[0])
  const [radius, setLocalRadius] = useState<number>(DEFAULT_RADIUS_KM)

  const { state } = useContext(TripContext)
  const { origin, destination } = state

  console.log("origin, destination", origin, destination)

  const originText = origin?.properties?.name
  const destinationText = destination?.properties?.name

  useEffect(() => {
    setRadius(radius)
  }, [radius])

  useEffect(() => {
    setLat(center[0])
    setLng(center[1])
  }, [center])

  const onChange = (text: string) => {
    setTerm(text)
    onSearch(text)
  }

  const updateCenter = () => {
    setCenter([parseFloat(lng), parseFloat(lat)])
  }

  return (
    <form className="py-8 px-8">
      <div className="mb-12">
        <div className="mb-4">
          <label
            className="text-md font-bold text-gray-900 block mb-2"
            htmlFor="search"
          >
            Search for a place
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="search"
            type="text"
            onChange={e => onChange(e.target.value)}
            value={term}
          />
        </div>
        <div className="mb-4">
          <label className="text-md font-bold text-gray-900 block mb-2">
            Origin
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="search"
            type="text"
            readOnly
            value={originText}
          />
        </div>
        <div className="mb-4">
          <label className="text-md font-bold text-gray-900 block mb-2">
            Destination
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="search"
            type="text"
            readOnly
            value={destinationText}
          />
        </div>
        <div className="mb-4">
          <label
            className="text-md font-bold text-gray-900 block mb-2"
            htmlFor="radius"
          >
            Search Radius in Kilometer
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="radius"
            type="text"
            onChange={e => setLocalRadius(e.target.value)}
            value={radius}
          />
        </div>
        <div className="mb-4">
          <label
            className="text-md font-bold text-gray-900 block mb-2"
            htmlFor="lat"
          >
            Center Latitude
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="lat"
            type="text"
            onChange={e => setLat(e.target.value)}
            value={lat}
          />
        </div>
        <div className="mb-4">
          <label
            className="text-md font-bold text-gray-900 block mb-2"
            htmlFor="lng"
          >
            Center Longitude
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            id="lng"
            type="text"
            onChange={e => setLng(e.target.value)}
            value={lng}
          />
        </div>
        <div className="">
          <a
            className="cursor-pointer inline-flex items-center justify-center px-3 py-1 bg-indigo-500 border border-transparent rounded-md text-white hover:bg-indigo-700 active:bg-red-700 focus:outline-none focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition"
            onClick={updateCenter}
          >
            Update Center
          </a>
        </div>
      </div>

      <DataTypeTable setNodeTypes={setNodeTypes} />
    </form>
  )
}

const DataTypeTable = ({ setNodeTypes }) => {
  const types = {
    venue: "points of interest, businesses, things with walls",
    address: "places with a street address",
    // street: "streets,roads,highways",
    neighbourhood: "social communities, neighbourhoods",
    borough:
      "a local administrative boundary, currently only used for New York City",
    localadmin: "local administrative boundaries",
    locality: "towns, hamlets, cities",
    county:
      "official governmental area; usually bigger than a locality, almost always smaller than a region",
    macrocounty: "a related group of counties. Mostly in Europe.",
    region: "states and provinces",
    macroregion: "a related group of regions. Mostly in Europe",
    country: "places that issue passports, nations, nation-states",
    coarse:
      "alias for simultaneously using all administrative layers (everything except venue and address)",
    postalcode: "postal code used by mail services",
  }

  const [selected, setSelected] = useState<NodeTypes>(Object.keys(types))

  useEffect(() => {
    setNodeTypes(selected)
  }, [])

  const toggleSelected = key => {
    const i = selected.indexOf(key)
    let copy

    if (i === -1) {
      copy = selected.concat(key)
    } else {
      copy = [...selected]
      copy.splice(i, 1)
    }

    setSelected(copy)
    setNodeTypes(copy)
  }

  const isSelected = key => selected.indexOf(key) !== -1

  return (
    <table className="mx-auto table-auto text-sm">
      <thead>
        <tr className="text-left bg-gradient-to-b from-indigo-500 to-purple-600">
          <th className="px-16 py-2">
            <span className="text-gray-100 font-semibold">Type</span>
          </th>
          <th className="px-16 py-2">
            <span className="text-gray-100 font-semibold">Description</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-gray-200">
        {Object.keys(types).map(key => (
          <tr key={key} className="bg-white border-b-2 border-gray-200">
            <td>
              <span className="text-center ml-2 font-semibold">
                <input
                  className="mr-4"
                  type="checkbox"
                  readOnly
                  onClick={() => toggleSelected(key)}
                  checked={isSelected(key)}
                />
                <span className="">{key}</span>
              </span>
            </td>
            <td className="px-16 py-2">
              <span className="text-center ml-2 font-semibold">
                {types[key]}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
